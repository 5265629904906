<template>
  <footer class="footer">
    <div class="footer__main">
      <nuxt-link
        class="footer__main_logo"
        to="/"
      >
        <img src="/v2/footer/logo.svg" alt="logo">
      </nuxt-link>
      <p class="footer__main_address">
        125315, г. Москва, Ленинградский пр-т, 80к48
      </p>
      <a
        class="footer__main_phone"
        :href="`tel:${phone}`"
      >
        {{ phone | prettyPhone }}
      </a>
      <div class="footer__main_soc">
        <p>пишите нам&nbsp;по&nbsp;любым вопросам</p>

        <div>
          <a
            href="https://wa.me/79686286327"
            target="_blank"
          >
            <img
              src="/v2/footer/whatsapp-fill.png"
              alt="whatsapp"
            >
          </a>

          <a
            href="https://t.me/schoolsynergy_bot"
            target="_blank"
          >
            <img
              src="/v2/footer/tg-fill.png"
              alt="telegram"
            >
          </a>

          <a
            href="mailto:nstolbova@synergy.ru"
            target="_blank"
          >
            <img
              src="/v2/footer/mail-fill.png"
              alt="email"
            >
          </a>
        </div>
      </div>
    </div>

    <div class="footer__license">
      <h3>государственная лицензия и&nbsp;аккредитация</h3>

      <button
        class="m-btn"
        @click="showLicensesModal = true"
      >
        проверить лицензию
      </button>

      <su-licenses-modal
        v-if="showLicensesModal"
        @close="showLicensesModal = false"
      />
    </div>

    <div class="footer__review">
      <b>4.9</b>
      <img src="/v2/footer/yandex.png" alt="Яндекс">
      <span>Яндекс</span>
    </div>

    <div class="footer__review">
      <b>4.7</b>
      <img src="/v2/footer/google.png" alt="Google">
      <span>Google</span>
    </div>

    <div class="footer__block footer__block--large">
      <div class="footer__links footer__links--school">
        <input id="school" type="checkbox">
        <label for="school">
          <span>школа</span>
          <img src="/v2/footer/arrow.svg" alt="arrow">
        </label>
        <div>
          <nuxt-link to="/online-school">
            домашняя школа
          </nuxt-link>
          <nuxt-link to="/school-university?class=2">
            it школа
          </nuxt-link>
          <nuxt-link to="/school-university?class=5">
            дизайн школа
          </nuxt-link>
          <nuxt-link to="/school-university?class=4">
            бизнес школа
          </nuxt-link>
          <nuxt-link to="/school-university?class=6">
            медиа школа
          </nuxt-link>
          <nuxt-link to="/externat">
            экстернат для детей
          </nuxt-link>
          <nuxt-link to="/externat-dlya-vzroslyh">
            экстернат для взрослых
          </nuxt-link>
        </div>
      </div>
      <div class="footer__links footer__links--ege">
        <input id="ege" type="checkbox">
        <label for="ege">
          <span>егэ</span>
          <img src="/v2/footer/arrow.svg" alt="arrow">
        </label>
        <div>
          <nuxt-link to="/ege">
            подготовка к егэ
          </nuxt-link>
          <nuxt-link to="/catalog/repetitor/ege">
            репетиторы егэ
          </nuxt-link>
          <nuxt-link to="/proforientacia">
            профориентация
          </nuxt-link>
          <button
            class="m-btn"
            @click="openModal(MODAL_TYPES.egeVuz)"
          >
            поступление в вуз
          </button>
          <nuxt-link to="/ege">
            бесплатные курсы егэ
          </nuxt-link>
        </div>
      </div>
      <div class="footer__links footer__links--oge">
        <input id="oge" type="checkbox">
        <label for="oge">
          <span>огэ</span>
          <img src="/v2/footer/arrow.svg" alt="arrow">
        </label>
        <div>
          <nuxt-link to="/oge">
            подготовка к огэ
          </nuxt-link>
          <nuxt-link to="/catalog/repetitor/oge">
            репетиторы огэ
          </nuxt-link>
          <nuxt-link to="/proforientacia">
            профориентация
          </nuxt-link>
          <button
            class="m-btn"
            @click="openModal(MODAL_TYPES.ogeCollege)"
          >
            поступление в вуз
          </button>
          <nuxt-link to="/oge">
            бесплатные курсы егэ
          </nuxt-link>
        </div>
      </div>
    </div>

    <div class="footer__block footer__block--1">
      <div class="footer__links footer__links--course">
        <input id="courses" type="checkbox">
        <label for="courses">
          <span>курсы и услуги</span>
          <img src="/v2/footer/arrow.svg" alt="arrow">
        </label>
        <div>
          <nuxt-link to="/catalog/repetitor">
            репетиторы
          </nuxt-link>
          <button
            class="m-btn"
            @click="openModal(MODAL_TYPES.languages)"
          >
            языковые курсы
          </button>
          <nuxt-link to="/catalog/programmirovanie-dlya-detej">
            it курсы
          </nuxt-link>
          <button
            class="m-btn"
            @click="openModal(MODAL_TYPES.skills)"
          >
            дизайн курсы
          </button>
          <button
            class="m-btn"
            @click="openModal(MODAL_TYPES.skills)"
          >
            бизнес курсы
          </button>
          <button
            class="m-btn"
            @click="openModal(MODAL_TYPES.skills)"
          >
            медиа курсы
          </button>
          <button
            class="m-btn"
            @click="openModal(MODAL_TYPES.soft)"
          >
            развитие soft&nbsp;skills
          </button>
        </div>
      </div>
    </div>

    <div class="footer__block footer__block--2">
      <div class="footer__links footer__links--useful">
        <input id="useful" type="checkbox">
        <label for="useful">
          <span>полезное</span>
          <img src="/v2/footer/arrow.svg" alt="arrow">
        </label>
        <div>
          <nuxt-link to="/online-school">
            о школе
          </nuxt-link>
          <!-- eslint-disable-next-line -->
          <a href="https://drive.google.com/file/d/1CEDuz8b6ANGnQR-wjpEJ_AKT8zAerzkr/view?usp=drive_link" target="_blank">
            поступление
          </a>
          <nuxt-link to="/documents">
            документы
          </nuxt-link>
          <nuxt-link to="/news">
            новости школы
          </nuxt-link>
          <nuxt-link to="/articles">
            блог
          </nuxt-link>
          <nuxt-link to="/school">
            статьи
          </nuxt-link>
        </div>
      </div>
    </div>

    <a
      class="footer__soc"
      href="https://t.me/synergyschool"
      target="_blank"
    >
      <img src="/v2/footer/tg-flat.png" alt="telegram">
      <span>синергия в тг</span>
    </a>

    <a
      class="footer__soc"
      href="https://vk.com/synergyonlineschool"
      target="_blank"
    >
      <img src="/v2/footer/vk-flat.png" alt="vk">
      <span>синергия в вк</span>
    </a>

    <a
      class="footer__soc"
      href="https://dzen.ru/schoolsynergy"
      target="_blank"
    >
      <img src="/v2/footer/dzen-flat.png" alt="dzen">
      <span>синергия в дзен</span>
    </a>

    <a
      class="footer__soc"
      href="https://www.youtube.com/@schoolsynergy"
      target="_blank"
    >
      <img src="/v2/footer/youtube-flat.png" alt="youtube">
      <span>синергия в youtube</span>
    </a>

    <div class="footer__bottom">
      <p>© Онлайн-школа «Синергия», 2024</p>

      <nuxt-link to="/privacy" class="footer__bottom_privacy">
        Политика конфиденциальности
      </nuxt-link>

      <nuxt-link to="/rekviziti" class="footer__bottom_rekviziti">
        Реквизиты
      </nuxt-link>
    </div>
  </footer>
</template>

<script>
import { mapActions } from 'vuex';
import '@/components/svg-icons/top-arrow';
import SuLicensesModal from '../school-university/SuLicensesModal.vue';

import { MODAL_TYPES } from './AppHeader/data';

export default {
  name: 'AppFooter',
  components: { SuLicensesModal },

  data: () => ({
    currentYear: new Date().getFullYear(),
    showLicensesModal: false,
    MODAL_TYPES,
  }),
  computed: {
    phone() {
      return process.env.phone;
    },
  },
  methods: {
    ...mapActions('appHeaderModals', {
      openModal: 'openModal',
    }),

    openModal(type) {
      // eslint-disable-next-line no-console
      console.log(type);
    },
  },
};
</script>

<style lang="scss" scoped>

.footer {
  padding-inline: calc(var(--scale) * 40);
  padding-bottom: calc(var(--scale) * 12);

  display: grid;
  gap: calc(var(--scale) * 20);
  grid-template-columns: repeat(4, calc(25% - (3.75rem / 4)));
  grid-template-rows:
    calc(var(--scale) * 130)
    calc(var(--scale) * 130)
    calc(var(--scale) * 578)
    calc(var(--scale) * 122)
    calc(var(--scale) * 21);

  font-family: "Onest", sans-serif;

  @include media-down($size-tablet) {
    padding-inline: calc(var(--scale) * 16);
    gap: calc(var(--scale) * 8);
    grid-template-columns: repeat(4, calc(25% - (1.5rem / 4)));
    grid-template-rows:
      calc(var(--scale) * 256)
      calc(var(--scale) * 56)
      calc(var(--scale) * 380)
      calc(var(--scale) * 380)
      calc(var(--scale) * 56)
      auto;
  }

  @include media-down($size-mobile) {
    padding-inline: calc(var(--scale) * 12);
    padding-bottom: calc(var(--scale) * 78) !important;
    column-gap: calc(var(--scale) * 4);
    grid-template-columns: repeat(2, calc(50% - (0.25rem / 2)));
    grid-template-rows:
      calc(var(--scale) * 320)
      calc(var(--scale) * 56)
      calc(var(--scale) * 128)
      repeat(3, auto)
      calc(var(--scale) * 56)
      calc(var(--scale) * 56)
      auto;
  }

  .footer__main {
    grid-column: 1 / 3;
    grid-row: 1 / 3;

    display: flex;
    align-items: flex-end;

    position: relative;
    background-image: url("/v2/footer/bg.webp");
    background-size: cover;
    border-radius: calc(var(--scale) * 40);
    padding: calc(var(--scale) * 34) calc(var(--scale) * 40) calc(var(--scale) * 37);

    @include media-down($size-tablet) {
      padding: calc(var(--scale) * 20) calc(var(--scale) * 16) calc(var(--scale) * 40);
      border-radius: calc(var(--scale) * 24);
      flex-direction: column;
      align-items: flex-start;
      background-image: url("/v2/footer/bg-mob.webp");
    }

    @include media-down($size-mobile) {
      grid-row: 1 / 2;
    }

    &_logo {
      position: absolute;
      left: calc(var(--scale) * 40);
      top: calc(var(--scale) * 40);

      @include media-down($size-tablet) {
        position: relative;
        left: 0;
        top: 0;
        order: 0;
        margin-bottom: calc(var(--scale) * 34);
      }

      img {
        height: calc(var(--scale) * 75);

        @include media-down($size-tablet) {
          height: calc(var(--scale) * 54);
        }
      }
    }

    &_address {
      width: calc(var(--scale) * 251);

      font-weight: 500;
      font-size: calc(var(--scale) * 16);
      line-height: 130%;
      color: rgba(#ffffff, 0.5);

      @include media-down($size-tablet) {
        margin-bottom: calc(var(--scale) * 30);
        width: calc(var(--scale) * 310);
        order: 2;
        font-size: calc(var(--scale) * 18);
      }
    }

    &_phone {
      margin-left: calc(var(--scale) * 19);
      padding-bottom: calc(var(--scale) * 5);

      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        margin-left: 0;
        margin-bottom: calc(var(--scale) * 16);
        padding-bottom: 0;
        font-size: calc(var(--scale) * 28);
        order: 1;
      }
    }

    &_soc {
      width: calc(var(--scale) * 280);
      margin-left: auto;

      @include media-down($size-tablet) {
        width: auto;
        display: flex;
        align-items: center;
        order: 3;
      }

      p {
        margin-bottom: calc(var(--scale) * 29);

        font-weight: 500;
        font-size: calc(var(--scale) * 32);
        line-height: 110%;
        color: rgba(#ffffff, 0.5);

        @include media-down($size-tablet) {
          margin-right: auto;
          margin-bottom: 0;
          font-size: calc(var(--scale) * 18);
        }
      }

      div {
        display: flex;
        gap: calc(var(--scale) * 8);

        img {
          width: calc(var(--scale) * 72);

          @include media-down($size-tablet) {
            width: calc(var(--scale) * 56);
          }
        }
      }
    }
  }

  .footer__license {
    grid-row: 1 / 3;
    padding: calc(var(--scale) * 32) calc(var(--scale) * 40);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    background-color: #FFFFFF;
    background-image: url("/v2/footer/gerb.svg");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right bottom;
    border-radius: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      grid-column: 3/5;
      grid-row: 1/2;
      padding: calc(var(--scale) * 12) calc(var(--scale) * 16);
      border-radius: calc(var(--scale) * 16);
      background-image: url("/v2/footer/gerb-mob.svg");
      background-size: auto 95%;
    }

    @include media-down($size-mobile) {
      background-size: auto 100%;
      grid-column: 1/3;
      grid-row: 3/4;
    }

    h3 {
      font-weight: 500;
      font-size: calc(var(--scale) * 32);
      line-height: 110%;
      color: #02120F;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 28);
      }

      @include media-down($size-mobile) {
        font-size: calc(var(--scale) * 20);
      }
    }

    button {
      font-weight: 400;
      font-size: calc(var(--scale) * 20);
      line-height: 130%;
      text-decoration-line: underline;
      color: #5237E6;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 18);
      }

      @include media-down($size-mobile) {
        font-weight: 500;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .footer__review {
    display: flex;
    align-items: center;
    padding-inline: calc(var(--scale) * 30);

    background-color: #FFFFFF;
    border-radius: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      padding-inline: calc(var(--scale) * 12);
      border-radius: calc(var(--scale) * 16);
    }

    b {
      flex-shrink: 0;
      width: calc(var(--scale) * 108);

      font-weight: 800;
      font-size: calc(var(--scale) * 75);
      line-height: 100%;
      letter-spacing: -0.06em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #02120F;

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 54);
        font-size: calc(var(--scale) * 40);
      }
    }

    img {
      width: calc(var(--scale) * 54);
      margin-left: calc(var(--scale) * 17);
      margin-right: calc(var(--scale) * 9);

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 28);
        margin-left: calc(var(--scale) * 6);
        margin-right: calc(var(--scale) * 4);
      }
    }

    span {
      font-weight: 500;
      font-size: calc(var(--scale) * 30);
      line-height: 100%;
      letter-spacing: -0.01em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #919191;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 16);
      }
    }
  }

  .footer__block {
    padding: calc(var(--scale) * 32) calc(var(--scale) * 40);
    background-color: #FFFFFF;
    border-radius: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      padding: calc(var(--scale) * 16);
      border-radius: calc(var(--scale) * 24);

      &.footer__block--1 {
        grid-column: 1 / 3;
      }

      &.footer__block--2 {
        grid-column: 3 / 5;
      }
    }

    @include media-down($size-mobile) {
      border-radius: calc(var(--scale) * 16);

      &.footer__block--2 {
        grid-column: 1/3;
      }
    }
  }

  .footer__block--large {
    grid-column: 1 / 3;
    display: flex;
    align-items: flex-start;

    @include media-down($size-tablet) {
      grid-column: 1 / 5;
    }

    @include media-down($size-mobile) {
      grid-column: 1/3;
      flex-direction: column;
      gap: calc(var(--scale) * 8);
      background-color: transparent;
      padding: 0;

      .footer__links {
        padding: calc(var(--scale) * 17) calc(var(--scale) * 16);
        border-radius: calc(var(--scale) * 16);
        background-color: #FFFFFF;
      }
    }
  }

  .footer__links {
    @include media-down($size-mobile) {
      width: 100%;
    }

    input {
      display: none;

      @include media-down($size-mobile) {
        &:checked ~ label {
          img {
            transform: rotate(180deg);
          }
        }

        &:checked ~ div {
          max-height: 100%;
          margin-top: calc(var(--scale) * 10);
        }
      }
    }

    label {
      margin-bottom: calc(var(--scale) * 32);

      font-weight: 500;
      font-size: calc(var(--scale) * 48);
      line-height: 110%;
      letter-spacing: -0.02em;

      @include media-down($size-tablet) {
        margin-bottom: calc(var(--scale) * 12);
        font-size: calc(var(--scale) * 20);
      }

      @include media-down($size-mobile) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
      }

      img {
        display: none;
        transition: 200ms;

        @include media-down($size-mobile) {
          display: block;
        }
      }
    }

    div {
      width: calc(var(--scale) * 270);
      display: flex;
      flex-direction: column;
      gap: calc(var(--scale) * 4);

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 238);
      }

      @include media-down($size-mobile) {
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: 200ms;
      }
    }

    a,
    button {
      display: block;
      padding-block: calc(var(--scale) * 9) calc(var(--scale) * 12);
      transition: 200ms;

      font-weight: 400;
      font-size: calc(var(--scale) * 20);
      line-height: 130%;
      color: #02120F;
      text-align: left;
      font-family: inherit;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 16);
      }

      @include media-down($size-mobile) {
        padding-block: calc(var(--scale) * 10) calc(var(--scale) * 14);
        font-size: calc(var(--scale) * 18);
      }
    }
  }

  .footer__links--school {
    label {
      color: #FF5319;
    }

    a:hover,
    button:hover {
      color: #FF5319;
    }
  }

  .footer__links--ege,
  .footer__links--oge {
    label {
      color: #0B72BE;
    }

    a:hover,
    button:hover {
      color: #0B72BE;
    }
  }

  .footer__links--course {
    label {
      color: #5237E6;
    }

    a:hover,
    button:hover {
      color: #5237E6;
    }
  }

  .footer__links--useful {
    label {
      color: #01410D;
    }

    a:hover,
    button:hover {
      color: #01410D;
    }
  }

  .footer__soc {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(var(--scale) * 10);

    background-color: #FFFFFF;
    border-radius: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      border-radius: calc(var(--scale) * 16);
      gap: 0;
    }

    &:hover span {
      color: #02120F;
    }

    img {
      width: calc(var(--scale) * 60);

      @include media-down($size-tablet) {
        width: calc(var(--scale) * 32);
      }
    }

    span {
      transition: 200ms;
      font-style: normal;
      font-weight: 500;
      font-size: calc(var(--scale) * 32);
      line-height: 110%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 14);
      }
    }
  }

  .footer__bottom {
    grid-column: 1 / 5;
    display: flex;
    align-items: center;

    @include media-down($size-tablet) {
      flex-wrap: wrap;
      gap: calc(var(--scale) * 12);
    }

    @include media-down($size-mobile) {
      grid-column: 1 / 3;
    }

    p {
      font-weight: 500;
      font-size: calc(var(--scale) * 16);
      line-height: 130%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        width: 100%;
        order: 1;
        text-align: center;
      }
    }

    a {
      font-weight: 500;
      font-size: calc(var(--scale) * 16);
      line-height: 130%;
      text-decoration-line: underline;
      color: #A7ABAC;
    }

    &_privacy {
      margin-left: auto;

      @include media-down($size-tablet) {
        margin-left: 0;
      }
    }

    &_rekviziti {
      margin-left: calc(var(--scale) * 231);

      @include media-down($size-tablet) {
        margin-left: auto;
      }
    }
  }
}

</style>
